

$page-base: #fff;
$ui-base: #f8f9fa;
$border-color: #dadce0;

$hov-bg: #f7f7f7;

$sel-bg: #ecf3ff;
$sel-color: #0062ff;

$toolbar-bg: #fff;
$toolbar-icon: #2e2e2e;

$brand-color-a: rgb(242, 91, 139);
$brand-color-b: rgb(249, 158, 158);
$brand-color-c: rgb(176, 151, 159);
$light-shadow: 0 1px 10px 3px rgb(60 64 67 / 10%);

$tool-pal-shadow: 0 0px 10px 7px rgb(60 64 67 / 10%);


$shadow: 1px 1px 31px 10px rgb(0 0 0 / 15%);

$tool-palette-bg: #8034dd55;
$tool-palette-hi: #8034dd;
$tool-palette-sel-hi: #5e28a0;
$tool-palette-lo: #974cf2;
$tool-palette-sel-lo: #8244cd;
$tool-palette-lo-hov: #915bd4;
$tool-palette-color: #f3e9ff;
$tool-palette-sel: #f9f6fd;
$tool-palette-bottom-color: #262626;
$tool-palette-sel-color: #d7d7d7;
$tool-palette-hov-bg: #8c2efe;

$acc-palette-bg: #efefef;
$acc-palette-sel: #f9f6fd;
$acc-palette-color: #faf5ff;
$acc-palette-hi: #34aadd;
$acc-palette-sel-hi: #eba62f;
$acc-palette-lo: #4cbdf2;
$acc-palette-sel-lo: #e4c142;
$acc-palette-lo-hov: #915bd4;

$hovered-note: #c2c2c2;
$hovered-note-bg: rgba(#fff,0);

$augmentd-checker-color: rgba(rgb(202, 1, 1), 0.8);
$augmentd-checker-int-bg: $augmentd-checker-color;

$range-checker-color: rgba(rgb(251, 84, 0), 0.8);
$range-checker-int-bg: $range-checker-color;

$vert-checker-color: rgba(rgb(255, 0, 157), 0.8);
$vert-checker-int-bg: $vert-checker-color;


$sev-checker-color: rgba(154, 64, 232, 0.8);
$sev-checker-int-bg: $sev-checker-color;

$checker-int-color: #fff;

$actionbar-bg: #fff;
$actionbar-shadow: 0 5px 10px 7px rgb(60 64 67 / 10%);

$fb-check: rgb(28, 206, 60)