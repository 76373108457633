@import './Colors.scss';

@font-face {
  font-family: Bravura;
  src: url("Bravura.otf") format("opentype");
}


html, body, #root, .App, .window, .editor, .document{
  height: 100%;
  overscroll-behavior-x: none;
  background-color: #f8f9fa;
  border:none;
  outline: none;
}

.App, .window {
  background-color: $ui-base;
}
.staff {
  position: relative;
}
.clef {
  position: absolute;
  font-size: 90px;
  top: 34px;
  font-family: 'Noto Music', sans-serif;
  z-index: 100;
}
.note {
  font-family: 'Noto Music', sans-serif;
  position: absolute;
  font-size: 70px;
  top: -73px;
  z-index: 10;
  cursor: grab;
  padding-left: 50px;
  padding-right: 50px;
  height: 90px;
  overflow: hidden;
  transition: transform,top 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  touch-action: none;
}
.note:active {
  text-shadow: 1px 1px 10px rgba(#000, 0.3);
}
.note:active:not(.note-flipped) {
  transform : scale(1.1);
  transform-origin: bottom;
}
.note:active.note-flipped  {
  transform: rotate(180deg) scale(1.1);
}

.staves {
  position: relative;
}

.staves .brace-left {
  top: calc(3rem + 5px);
  left: -7.1px;
  float: left;
  width: 20px;
  height: 100%;
  position: absolute;
}

.brace-left .brace-top,
.staves .brace-left .brace-bottom {
  position: absolute;
  width: 10px;
  height: 50%;
}

.staves .brace-left .brace-top {
  top: 0;
}

.staves .brace-left .brace-bottom {
  top: 50%;
}

.staves .brace-left .brace-top:before,
.staves .brace-left .brace-top:after,
.staves .brace-left .brace-bottom:before,
.staves .brace-left .brace-bottom:after {
  content: "";
  height: 50%;
  width: 10px;
  display: block;
  position: absolute;
}

.staves .brace-left .brace-top:before {
  top: 0;
  left: 0;
  border-left: 3px solid black;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
}

.staves .brace-left .brace-top:after {
  bottom: 0;
  left: -7.1px;
  border-right: 3px solid black;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px;
}

.staves .brace-left .brace-bottom:before {
  top: 0;
  left: -7px;
  border-right: 3px solid black;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
}

.staves .brace-left .brace-bottom:after {
  bottom: 0;
  left: 0;
  border-left: 3px solid black;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-left-radius: 10px;
}

.brace {
  font-family: 'Bravura', sans-serif;
  font-size: 312px;
  position: absolute;
  top: 8.1rem;
  transform: translateX(-90%);
}
.dur-1 {
  /*translate: -25%;*/
}
.note-crowd-l {
  translate: 5px;
}
.accidental.note-crowd-l {
  translate: (0px 6px);
}
.accidental.note-crowd-r {
  translate: (-12px);
}
.note-crowd-r {
  translate: -5px;
}
.note:hover:not(.note-sel) {
  color: $sel-color;
}
@media print {
  .note {
    color: black !important;
  }
}
.note-sel {
  color: $sel-color;
}
.note-flipped {
  transform: rotate(180deg);
  top: -7px;
}
.note-flipped.dur-1:not(.note-beamed) {
  translate: -30%;
}
.bed {
  position: relative;
}

.line {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  box-sizing: border-box;
  background: #000;
  max-width: 100%;
  overflow-x:hidden;
}

.line:after {
  display:none;
}
.gutter-top {
  top: -20px;
  position: relative;
}

.gutter-bot {
  position: relative;
  top: 30px;
}

.ll {
  width: 33px;
  transform: translateX(160%);
}
.ll > hr {
  border: 0.7px solid black;
  opacity: 1;
  z-index: -4;
}


.editor {
  display: flex;
  flex-direction: row;
  align-items:center;
  position: relative;
  background-color: $ui-base;
  top: 30px;
}
.document {
  flex-grow: 1;
  display: flex;
  align-items: start;
}
.window.disable-scroll {
  overflow-y: hidden;
}
.page {
  box-shadow: $light-shadow;
  background-color: $page-base;
  display: flex;
  align-items: center;
  justify-content: center;
}


.page, .page * {
  color-scheme: only light !important;
}

.toolbar {
  width: 100%;
  height:50px;
  z-index: 100000;
  display: flex;
  align-items: center;
  color: $toolbar-icon;
  font-family:Circular;
  position: fixed;
  padding-top: 10px;
}
.toolbar.frost {
  backdrop-filter: blur(5px);
  background: rgba(#efefef,0.8)
}
.buttonbar {
  right: 0;
  position: fixed;
  display: flex;
}

.toolbar-btn {
  line-height: 25px;
  background-clip: content-box;
  font-weight: bold;
}

.toolbar-btn:active, .toolbar-btn.sel:not(.tool-palette-top > div) {
  color: $sel-color;
  background: $sel-bg;
}
.toolbar-btn:hover {
  background: $hov-bg;
}

@font-face {
  font-family: Circular;
  src: url("CircularStd-Black.otf") format("opentype");
}
@font-face {
  font-family: CircularBook;
  src: url("CircularStd-Book.otf") format("opentype");
}
@font-face {
  font-family: CircularLight;
  src: url("CircularStd-Light.otf") format("opentype");
}

.brand-print .brand-icon {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.brand-icon img {
  height: 40px;
  width: 40px;
  margin-left: -10px;
}
.toolbar .toolbar-brand {
  padding-left: 25px;
}
.toolbar-brand .title {
  letter-spacing: -2.5px;
}
.toolbar-brand:not(.brand-tld) {
  font-weight: bold;
  color: $brand-color-a;
  font-family:Circular;
  font-size: 30px;
  /*text-shadow: 1px 1px rgba(darken($brand-color-a, 30), 0.5);*/
}
.brand-tld {
  font-family: CircularLight;
  color: lighten($brand-color-a, 13);
  margin-top: 12px;
}
.dot:not(.brand-print .dot) {
  color: $brand-color-c;
}
.brand-print {
  .dot {
    font-size: 35px;
    opacity: 0.6;
    margin-left: -1px;
    margin-top: -3px;
  }
  .brand-icon img {
    margin-right: 5px;
  }
  .brand-tld {
    margin-left: -2px;
    font-size: 15px;
  }
}

.toolbar-right {
  position: absolute;
  right: 3rem;
  display: flex;
}
.panel {
  left: 0;
  bottom:auto;
  margin: 0px 0px 0px 17px;
  background-clip:content-box;
  width: 50px;
  z-index: 50000000;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  box-shadow: $tool-pal-shadow;
  zoom: 1.2;
  border-radius: 40px;
  overflow: hidden;
  flex-shrink: 0;
  font-family:Circular;
  transition: .2s all cubic-bezier(.36,.18,.92,1.37) !important;
}
.page, .toolbar {
  transition: .2s padding-left cubic-bezier(.36,.18,.92,1.37),
              .2s margin-left cubic-bezier(.36,.18,.92,1.37) !important;
}
.panel:not(:hover) .panel-close {
  opacity: 0;
}
.panel-close {
  color: #efefef;
  text-align: center;
  transition: 0.3s all;
}
.panel-close:hover {
  color: #d0d0d0;
  text-align: center;
}
.panel.closed {
  height: 50px;
  top: calc( 100% - 74px );
}
.panel.open {
  .tool-palette-closed {
    display: none;
  }
  height: 575px;
  top: 129px;
}
.panel, .page {
  transition: box-shadow 0.1s ease-in-out;
}
.tool-palette-top,.tool-palette-closed {
  background: $tool-palette-bg;
  backdrop-filter: blur(10px);
}
.panel:hover {
    box-shadow: $shadow;
}
.panel:hover {
  scale: 1.20;
  transform: translateY(-10px);
  border-radius: 10px;
}
.tool-palette:hover:not(.tool-palette-bottom .toolbar-btn) {
  transform: scale(1.2);

  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.note-palette {
  font-family: 'Noto Music', sans-serif;
  font-size: 30px;
  line-height: 30px;
}
.tool-palette {
  text-align: center;
  aspect-ratio: 1/1;
  color: $tool-palette-color;
  transition: box-shadow,line-height,margin-top 0.2s cubic-bezier(.36,.18,.92,1.37);
}
.tool-palette-top > .tool-palette,
.tool-palette-closed > .tool-palette{
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 10px;
  overflow: hidden;
  line-height:10px;
  box-shadow: inset $tool-palette-hi -5px -0.3pc, inset $tool-palette-lo 10px 5pc;
  transition: box-shadow,line-height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow,line-height,margin-top 0.2s cubic-bezier(.36,.18,.92,1.37);
}
.tool-palette-top > .tool-palette.sel,
.tool-palette-closed > .tool-palette.sel, 
.tool-palette-top > .tool-palette:active,
.tool-palette-closed > .tool-palette:active{
  line-height:14px;
  color: $tool-palette-sel;
  box-shadow: inset $tool-palette-sel-hi 5px 0.3pc, inset $tool-palette-sel-lo 10px 2pc;
}

.tool-palette-top > .eighth-palette,
.tool-palette-closed > .eighth-palette {
  font-size: 22px;
  padding-left: 1px;
  line-height: 20px !important;
}
.tool-palette-top > .eighth-palette.sel,
.tool-palette-closed > .eighth-palette.sel, 
.tool-palette-top  >  .eighth-palette:active,
.tool-palette-closed >  .eighth-palette:active{
  line-height:25px !important;
}

.tool-palette-top > .tool-palette:hover,
.tool-palette-closed > .tool-palette:hover {
  color: #fff;
  background-color: $tool-palette-lo-hov;
}
.tool-palette-top > .tool-palette:not(.sel):not(.eighth-palette),
.tool-palette-closed > .tool-palette:not(.sel):not(.eighth-palette) {
  text-align: left;
  padding-left: 8px;
}



.acc-palette {
  text-align: center;
  aspect-ratio: 1/1;
  color: $acc-palette-color;
  font-size: 30px;

}
.acc-palette {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 10px;
  overflow: hidden;
  line-height:28px;
  box-shadow: inset $acc-palette-hi -5px -0.3pc, inset $acc-palette-lo 10px 2pc;
  transition: box-shadow,line-height,margin-top 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-family: 'Arial Unicode MS', 'Lucida Sans Unicode';
}
.acc-palette.sel, .acc-palette:active{
  line-height:30px;
  color: $acc-palette-sel !important;
  padding-left: -2px;
  box-shadow: inset $acc-palette-sel-hi 5px 0.3pc, inset $acc-palette-sel-lo 10px 2pc;
}
.acc-palette:hover {
  color: #fff;
  background-color: $acc-palette-lo-hov;
}
.acc-palette:not(.sel) {
  text-align: left;
  padding-left: 6px;
}
.tool-palette-mid {
  background-color: rgba($acc-palette-bg, 0.8);
  backdrop-filter: blur(10px);
}
.tool-palette-bottom {
  padding: 10px;
  background-color: rgba(#fff, 0.8);
  backdrop-filter: blur(10px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.tool-palette-bottom > .tool-palette {
  line-height: 30px;
  color: $tool-palette-bottom-color;
  margin-top: 5px;
}
.tool-palette-bottom > .toolbar-btn:active, .tool-palette-bottom > .toolbar-btn.sel {
  color: $sel-color;
  background: $sel-bg;
}
.tool-palette-bottom > .toolbar-btn:hover {
  background: $hov-bg !important;
}

.tool-palette:active, .tool-palette.sel {
  color: $tool-palette-sel-color;
}
.tool-palette:hover:not(.sel):not(.hovered-note) {
  background: $tool-palette-hov-bg;
}

.hovered-note {
  color: $hovered-note !important;
  background-color: $hovered-note-bg;
  width: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; 
}
@media screen {
  .page {
    margin-left: 70px;
    margin-top: 45px;
    position: relative;
  }
  .document {
    padding-right: 80px;
  }
  .page-content {
    padding-top: 0.6in;
    padding-bottom: 0.6in;
    padding-left: 0.6in;
    padding-right: 0.6in;  
    overflow: hidden; 
    position: relative;
    border-radius: 5px;
  }

}
@media print
{    
  .page-content {
    padding-top: calc(0.75in + 2rem);
    padding-bottom: 0.75in;
    padding-left: 0.75in;
    padding-right: 0.75in;  
    overflow: hidden; 
    position: relative;
  }
  .page-content {
    padding: 0;
    overflow:visible;
    -webkit-print-color-adjust: exact;
  }

  .App, .window, .editor {
    left: 0;
    position: absolute;
  }

  body {
    margin-top:0 !important;
    zoom: 0.72
  }
  html, body, #root, .App, .window, .editor, .document {
    background: #fff;
  }
  @page {
    size: 11in 8.5in;
  }
  .editor {
    top: 0;
  }
  .page {
    scale: 0.9;
  }
  .document {
    position: absolute;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: visible;
  }
  .bass-figure {
    background: unset !important;
  }
  body * {
    visibility: hidden;
  }
  .page, .page * {
    visibility: visible;
  }
  .page {
    left: 0;
    box-shadow: none; 
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    margin: 0;
    min-width: unset !important;
    aspect-ratio: 11 / 8.5;
  }
  * {
    box-shadow: none !important;
  }
  .checker-line {
    display: none;
  }
  .checker-int {
    display: none;
  }
}
.brand-print {
  visibility: visible !important;
  position: absolute;
  right: 3rem;
  bottom: 0rem;
  color: #ccc;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ccc;
  display: flex;
  align-items: center;
}
.brand-print * {
  visibility: visible !important;
}

.dividers {
  position: absolute;
  top: -1.5rem;
  padding-bottom: 6rem;
}
.divider {
  height: 48%;
  background: rgb(170, 170, 170);
  width: 1px;
  display: block;
  box-sizing: border-box;
  margin-top: 5px;

  position: absolute;
}

.sc {
  width: 40px;
  text-align: center;
}
.time-signature {
  font-family: Bravura, 'Arial Unicode MS', 'Lucida Sans Unicode';
  font-size: 70px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1.3rem;
  justify-content: center;
}
.time-signature > div {
  height: 50px;
}
.time-signature .ts-lower {
  margin-top: -10px;
}
.key-signature {
  position: absolute;
  top: calc(3rem - 47px);
}
.key-sig-item {
  position: absolute;
  font-size: 70px;
  font-family: Bravura, 'Arial Unicode MS', 'Lucida Sans Unicode';
}
.accidental {
  font-size: 70px;
  position: absolute;
  transform: translate(-0.25rem,-46px);
  font-family: Bravura, 'Arial Unicode MS', 'Lucida Sans Unicode';
}

@font-face {
  font-family: Figurato;
  src: url("Figurato.otf") format("opentype");
}

.figured-bass, .chord-names {
  position: relative;
  left: 3rem;
  transform: translateX(-20px);
  min-height: 150px;
}

.chord-names {
  transform: unset;
  min-height: 45px;
  transform: translateX(8px);
  font-family: Circular;
  font-size: 25px;
}
.chord-name {
  display: flex;
  justify-content: center;
  min-height: inherit !important;
  transition: 0.2s transform ease-in-out;
}
.chord-name:hover {
  background-color: #efefef;
  border-radius: 50%;
  padding: 5px;
}
.chord-name:active {
  transform: scale(1.05);
}
.fb-check {
  margin-left: 1.3rem;
  transform: translateY(150px);
  padding:5px;
  width: 30px!important;
  height: 30px!important;
  background: $fb-check;
  color: #fff;
  min-height: unset !important;
  line-height: 20px;
  border-style: solid;
  border-radius: 50%!important;
  border: none;
}

.fb-check-content  {
  animation-name: fb-check-in;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  position:  absolute; 
  margin-left: 2px;
}
/* The animation code */
@keyframes fb-check-in {
  from {
    clip-path: circle(0px at -5px 0px);
  }
  20% {
    clip-path: circle(10px at 0px 19px);
  }
  30% {
    clip-path: circle(20px at 0px 20px);
  }
  to {
    clip-path: circle(50px at 0px 5px);
  }
}

@media print{
  .fb-check, .bass-figure-plus {
    display: none;
  }
}
.bass-figure, .chord-name, .fb-check {
  position: absolute;
  min-height: 105px;
  width: 45px;
  text-align: center;
  padding: 5px;
  border-radius: 15px;
}
.bass-figure.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bass-figure {
  font-family: Figurato;
  font-size: 30px;
  transform: translateX(-12px)
}
.bass-figure.hover {
  background-color: #efefef;
}
.bass-figure:focus {
  background-color: #cfcfcf;
  outline: 2px solid $sel-color;
}

.checker-line-wrapper {
  position: absolute;
  top: 0;
  margin-left: 0.75in;
  margin-top: 0.5in;
}
.checker-line-wrapper>div {
  position: absolute;
  top: 0;
}
.checker-line-vert {
  width: 1px;
  transform: translateX(5px);
}
.line-to-top {
  transform: translate(5px, 5px);
}
.line-to-bottom {
  transform: translate(5px, 5px);
}
.line-to-bottom .checker-line  {
  transform-origin: 0% 100%;
}
.line-to-bottom  .checker-int {
  transform: scale(0.8) translateX(-50%) translateY(-15%);
  top: 40%;
}

.checker-int {
  left: 50%;
  position: absolute;
  transform: translateX(-50%) scale(0.8);
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  line-height: 20px;
  font-weight: bold;
  font-family: Circular;
  backdrop-filter: blur(10px);
  color: $checker-int-color;
}

.checker-int:hover {
  transform: translateX(-50%) scale(1.1);
}
.checker-line-wrapper {
  position: absolute;
  top: 6rem;
}

.checker-type-augmented .checker-int {
  background-color: rgba($augmentd-checker-int-bg, 0.8);
}
.checker-type-leap .checker-int {
  background-color: rgba($range-checker-int-bg, 0.8);
}
.checker-type-sev .checker-int {
  background-color: rgba($sev-checker-int-bg, 0.8);
}

.checker-type-augmented .checker-line {
  border: 1px dotted $augmentd-checker-int-bg;
}

.checker-type-leap .checker-line {
  border: 1px dotted $range-checker-int-bg;
}
.checker-type-sev .checker-line {
  border: 1px dotted $sev-checker-int-bg;
}
.checker-line-vert .checker-int {
  background-color: rgba($vert-checker-int-bg, 0.8);
}

.checker-line-vert .checker-line {
  border: 1px dotted $vert-checker-int-bg;
  height: 100%;
}

.beam-wrapper {
  position: absolute;
}
.beam-wrapper>div{
  overflow:hidden;

}
.beam {
  height: 5px;
  background: black;
  border-left: 20px solid black;
}

.beam-wrapper-top:not(.beam-flat) {
  top: -58px;
}
.beam-wrapper-bottom.beam-flat {
  top: 63px;
}
.beam-wrapper-top.beam-flat {
  top: -58px;
}
.beam-wrapper-bottom:not(.beam-flat)  {
  top: 63px;

}
.beam-top {
  transform-origin: bottom left;
}
.beam-bottom {
  transform-origin: top left;
}

.gutter-top > .note-flipped {
  margin-top: 63px;
}

.gutter-bot > .note:not(.note-flipped) {
  margin-top: -63px;
}

.actionbar {
  display: flex;
  z-index: 5000000;  
}
.dropdown-menu {
  z-index: 100000;
  top: 31px;
  left: 0;
  box-shadow: $actionbar-shadow;
  border-top: none;
  border-radius: 0px 0 10px 0;
  padding: 0;
  min-width: 18rem;
  background: rgba(#fff, 0.5);
  backdrop-filter: blur(10px);
}
.dropdown-menu .dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dropdown-spacer {
  width: 16px;
}
.dropdown-item-icon {
  width: 26px;
  display: inline-block;
}
.dropdown-menu .dropdown-item:last-child {
  border-radius: 0px 0 10px 0;
}
.actionbar-item {
  padding: 3px 10px;
  position: relative;
  border-radius: 5px;
  font-family: CircularBook;
}
.actionbar-item:active {
  background: darken($sel-bg,5) !important;
  color: $sel-color;
}
.actionbar-item.active{
  background: $sel-bg;
  color: $sel-color;
}
.shortcut {
  float: right;
  color: lighten(#000, 80)
}
.dot-up {
  transform: translateY(10px);
  display: inline-block;
}
.dot-down {
  transform: translate(-6px, 10px);
  display: inline-block;
}

.bed-line .dot-up {
  transform: translateY(0px) !important;
  display: inline-block;
}
.bed-line .dot-down {
  transform: translate(-6px, 0px) !important;
  display: inline-block;
}

.setup-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 100000;
  backdrop-filter: blur(5px);
}
.setup {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  background-color: #fff;
  box-shadow: $shadow;
  width: 400px;
  padding: 50px;
  padding-bottom: 100px;
  border-radius: 5px;
}

.input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;
}
.val input {
  width: 50px;
  text-align: center;
}
.val {
  display: flex;
  flex-direction: column;
}


.nct {
  position: absolute;
  margin-left: 56px;
  margin-top: -30px;
}

@media print {
  .play-button-wrapper, .play-button, .play-fish {
    display: none !important;
  }
}
.play-button-wrapper {
  position: absolute;
  right: -50px;
  bottom: -50px;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #11e357;
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  z-index: 1000;
}
.play-button-wrapper:hover, .mixer.closed:hover {
  box-shadow: rgba(#000, 0.1) 5px 5px 5px;
  transform: scale(1.05);
}
.play-button-wrapper:active {
  transform: scale(0.95);
  background-color: #0ec74c;
}
.play-button-wrapper.fixed {
  position: fixed;
  right: 35px;
  bottom: 35px;
  
}
.play-fish {
  position: relative;
  transform-origin: center;
  font-size: 30px;
  transition: left 0.15s ease-out;
  width: 50px;
  color: rgb(242, 91, 139);
  opacity: 0;
  transform-origin: center;
}
.visible {
  opacity: 1;
}
.bounce {
  animation: bounce 0.3s ease-in-out;
}

@keyframes bounce {
  3% {
    transform: translateY(-20%) scale(0.9,1.1) rotate(50deg)  
  }
  25% {
    transform: translateY(-12%) scale(0.8,1) rotate(15deg)
  }
  50% {
    transform: translateY(0%) scale(1.3,1) rotate(0deg)
  }
  55% {
    transform: translateY(-5%) scale(1,1) 
  }
  100% {
    transform: translateY(0%) scale(1,1)
  }
}
body {
  overflow: scroll;
}

.f-visible {
  transform: scale(1);
  transition: all 0.1s cubic-bezier(.36,.18,.92,1.37);

}
.f-invisible {
  transform: scale(0);
  transition: all 0.1s cubic-bezier(.36,.18,.92,1.37);
}

.mixer {
  position: fixed;
  left: 70px;
  bottom: 35px;
  display: flex;
  align-items: end;
  z-index: 150000000;
  background: #484848;

  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: rgba(#000, 0.1) 5px 5px 5px;
  backdrop-filter: blur(5px);
  overflow:hidden;

}
.slider {
  height: 100%;
  display: flex;
  align-items: end;
}

.meter-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: end;
}
.mixer-buttons {
  display: flex;
  margin-bottom: 10px;
 }
.mixer-volume-buttons {
  width: 30px;
  height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(159, 159, 159);
  background: linear-gradient(180deg, rgb(128, 128, 128) 0%, rgb(97, 97, 97) 100%);

  -webkit-box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);
-moz-box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);
box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);

  border-radius: 5px;
  color: white;
  font-weight: bold;
  border: 2px solid rgb(112, 112, 112);
}
.mixer-volume-buttons:not(:last-child) {
  margin-right: 10px;
}
.slider-button {
  background: rgb(200, 199, 199);
  width: 25px;
  height: 55px;
  position: relative;
  border: 2px solid rgb(77, 77, 77);
  border-radius: 7px;
}
.led-container {
  background-color: #223c26;
  height: 100%;
  margin-left: 10px;
  display: flex;
  align-items: end;

  -webkit-box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);
  -moz-box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);
  box-shadow: inset -11px -15px 32px 6px rgba(0,0,0,0.2);

}
.mixer-voice {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meter {
  width: 20px;
  background-color: #0ec74c;
  background: linear-gradient(180deg, rgb(5, 240, 1) 0%, rgb(7, 168, 5) 100%);
  border: 2px solid rgb(15, 92, 31);
}
.strip {
  height: 100%;
  border: 1px solid #0f0f0f;
  position: relative;
    left: -12px;
    z-index: -10
}

.mixer-mute.enabled {
  background: linear-gradient(180deg, rgb(244, 105, 30) 0%, rgb(187, 95, 9) 100%);
border: 2px solid rgb(104, 65, 15);
}

.mixer-solo.enabled {
  background: linear-gradient(180deg, rgb(240, 244, 30) 0%, rgb(187, 172, 9) 100%);
border: 2px solid rgb(139, 144, 5);
}

.mixer-volume-buttons.enabled {
  box-shadow: inset 1px 2px 5px 2px rgba(0,0,0,0.3) ;
}
.mixer-voice {
  padding: 5px;
  margin: 5px;
  border: 1px solid #474747;
  background: #434343;
  border-radius: 5px;
  z-index: -5000;
  position: relative;
}

.mixer-head {
  color: white;
}

.mixer, .mixer *:not(.slider-button) {
  transition: 0.2s all ease-in-out;
}

.mixer.open {
  height: 345px;
  width: 410px;
  background: #000000b5;
  box-shadow: rgba(#000, 0.3) 5px 5px 10px;
}
.mixer.closed {
  height: 50px;
  width: 250px;
}

.mixer-closed {
  position: absolute;
  left: 30px;
  width: 500px;
  color: white;
  font-weight: bold;
  transform: translateY(-1px);
  margin-left: 7px;
}
.mixer-bd-closed {
  height: 100%;
  width: 100%;
  position: absolute;
  backdrop-filter: blur(5px);
  background-color: rgba(#000, 0.4);
  top: 0;
  left: 27px;
}
.mixer.open .mixer-bd-closed {
  opacity: 0;
  display: none;
}
.mixer.open .mixer-closed{
  width: 0;
  opacity: 0;
}

.mixer.closed .mixer-head {
  transform: translate(-2px,-1px);
}
.mixer.open .mixer-head {
  margin-right: 10px;
  z-index: 50000;
  transform: translate(5px,-1px);
}
.mixer.open > .mixer-head {
  color: #c4c4c4;
}
.mixer-voice > .mixer-head {
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.mixer-voice > .mixer-head .mixer-icon img {
  height: 25px;
}
.mixer-voice > .mixer-head .mixer-icon {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 50%;
}
.mixer-voice > .mixer-head .mixer-icon:hover {
  background: rgb(0,0,0,0.2);
  width: 40px;
  height: 40px;
}
.mixer-voice > .mixer-head .mixer-icon:hover img {
  transform: scale(1.1);
}
.mixer-voice > .mixer-head .mixer-icon:active img {
  transform: scale(0.8);
}

.mixer.open > .mixer-head > svg {
  transform: rotate(45deg);
}

.mixer-style-stripe {
  height: 100%;
  top: 0;
  left: 0;
  width: 33px;
  background: rgba(#2f2f2f,1);
  position: absolute;
  z-index: -1;
  box-shadow: rgba(0,0,0,0.6) 5px 5px 30px;
}
.mixer.closed {
  .mixer-style-stripe {
    opacity: 0;
  }
}
.mix-picker {
  position:fixed;
  left: 121px;
  bottom: 320px;
  z-index: 500000000;
  height: 130px;
  display: flex;
  align-items: end;
}
.mixer-picker-wrap {
  position:absolute;
  display: flex;
  flex-direction: column;
  margin-top: -80px;
  background: rgba(0,0,0,0.7);
  backdrop-filter: blur(0.5);
  padding: 10px;
  border-radius: 50px;
  overflow: hidden;
  transition: height .1s ease-in-out,
              opacity .1s ease-in-out;
  box-shadow: rgba(#000, 0.1) 5px 5px 5px;
}
.mixer-picker-wrap.closed {
    height: 60px;
    opacity: 0;
    pointer-events: none;;
    
}
.mixer-picker-wrap.open {
  height: 410px;
  opacity: 1;
}
.mixer-picker-wrap {
  
  img, span { 
    height: 40px; 
    width: 40px;
    background: rgba(150, 150, 150, 0.4);
    padding: 5px;
    border-radius: 50%;
  }
  span {
    font-size: 20px;
    line-height: 30px;
    display: block;
    color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mixer-icon:not(:last-child) > img, .mixer-icon:not(:last-child) > span {
    margin-bottom: 10px;
  }
  img:hover { 
    transform: scale(1.1);
  }
  img:active {
    transform: scale(0.8);
  }
}